import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { delay } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service'
import { ParamsService } from 'src/app/shared/services/params.service';
import { Subscription } from 'rxjs';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
    selector: 'app-submittals-product-categories',
    templateUrl: './submittals-product-categories.component.html',
    styleUrls: ['./submittals-product-categories.component.scss'],
})

export class SubmittalsProductCategoriesComponent implements OnInit, OnDestroy {

    public stepperObject: Array<any> = [];
    public productCategories: any = {};
    public imgBaseURL: string = '';
    public spinner$: boolean = false;
    public error$: boolean = false;
    public selectedByProductObject: Array<any> = [];
    private params: any = {};
    private observeStepperObj: Subscription;
    public parenterrormessage: object = {};
    public firestopLink: string = '';

    constructor(
        public activatedRoute: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private httpRequestService: HttpRequestService,
        public sessionStorageService: SessionStorageService,
        private paramsService: ParamsService,
        public config: LanguageConfig,
        private tagService: GoogleTagManagerService
    ) {
        this.params = paramsService.getParams() || {};
        // here we are subscribing to the global stepper object to update the steps in stepper component.
        this.observeStepperObj = this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = Object.assign([], c);
            });
    }

    ngOnInit() {
        this.spinner$ = true;

        /**
         * As a user generating submittal, I should be able to create a UL firestop submittal only 
         * when I selected Firestop & Fire protection category.
         * 
         * Acceptance Criteria
         * 1.  Create UL firestop submittal link should come only when Firestop & Fireprotection category is selected
         * 2. Link for US should be https://www.hilti.com/firestops
         * 3. Link for CA should be https://www.hilti.ca/firestops
         */
        // this.firestopLink = 'https://www.hilti.' + (window.sessionStorage.country === 'CA' ? 'ca' : 'com') + '/firestops';

        this.firestopLink = 'https://firestop.hilti.com/microapp/firestop/search-type';

        this.activatedRoute.data.subscribe(((obj: any) => {
            let productCategories = obj.productCategories || {};
            this.spinner$ = false;

            if (productCategories.error) {
                this.productCategories = [];
                this.onErrorResponse(productCategories.error);
            } else {
                this.error$ = false;
                this.imgBaseURL = productCategories.assetHostName;
                this.productCategories = productCategories;
            }
        }),
            (err => this.onErrorResponse(err)));

        this.selectedByProductObject = this.sessionStorageService.getByProductObject();

        if (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length > 0) {
            this.changeStepper();
        } else {
            this.stepperWithoutSections();
        }
    };

    prepareStepper(isSection) {
        this.stepperObject = [];

        let stepper = 0;

        this.stepperObject.push({
            stepValue: stepper,
            title: this.selectedByProductObject[0].values.CHA_CLASSIFICATION_PUBLICATION_DESC,
            routeUrl: '/by-products',
            stepperProgressCount: 0
        });

        ++stepper;

        this.stepperObject.push({
            stepValue: stepper,
            title: 'STEPPER.PRODUCT_CATEGORY',
            routeUrl: '/by-products/' + this.activatedRoute.snapshot.params['id'],
            stepperProgressCount: 0
        });

        if (isSection) {
            ++stepper;

            this.stepperObject.push({
                stepValue: stepper,
                title: 'STEPPER.SECTIONS',
                stepperProgressCount: 0
            });
        }

        ++stepper;

        this.stepperObject.push({
            stepValue: stepper,
            title: 'STEPPER.PRODUCTS',
            stepperProgressCount: 0
        });

        ++stepper;

        this.stepperObject.push({
            stepValue: stepper,
            title: 'STEPPER.DOCUMENTS',
            stepperProgressCount: 0
        });

        this.dataService.changeStepperObjectGlobal(this.stepperObject);
    }

    /* stepperWithoutSections will set the stepper to the defalut values for by products */
    stepperWithoutSections() {
        this.prepareStepper(false);
    }

    /* changeStepper will set the stepper to the changed values with 'sub category' for by products */
    changeStepper() {
        this.prepareStepper(true);

        return true;
    }

    // 'nextToDocument' will change the stepper behavior obeject and then navigate to the next step 
    nextToDocument(id, selectedProdCategoryObj) {
        selectedProdCategoryObj.assetHostName = this.imgBaseURL;
        /* creating product category object to save in session to display summary and create submittals */
        let tempByProductObject = {
            accId: 1,
            TITLE: 'STEPPER.PRODUCT_CATEGORY',
            selectedItems: [selectedProdCategoryObj]
        }

        this.selectedByProductObject.splice(1, this.selectedByProductObject.length);

        this.selectedByProductObject.push(tempByProductObject);

        this.sessionStorageService.setByProductObject(this.selectedByProductObject);

        const navigationExtras: NavigationExtras = {
            relativeTo: this.activatedRoute
        };

        this.activatedRoute.params.subscribe(params => {
            let subChapterId = id;
            let chapterId = params['id'];
            this.spinner$ = true;

            this.httpRequestService.getWithToken(this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/chapters/' + chapterId + '/subChapters/' + subChapterId + '/sections')
                .subscribe(((obj: any) => {
                    this.spinner$ = false;
                    if (obj.isRanges) {
                        this.dataService.changeSectionsOrProductsObject({ products: obj })
                        this.stepperWithoutSections();
                        this.setStepperSteps();

                        this.tagService.setGMTDataLayer({
                            'stepNumber': 'Step4',
                            'pageName': 'Products'
                        }, this.selectedByProductObject);

                        this.router.navigate(['product-category', id], navigationExtras);
                    } else {
                        this.dataService.changeSectionsOrProductsObject({ sections: obj })
                        if (this.changeStepper()) {
                            this.setStepperSteps();

                            this.tagService.setGMTDataLayer({
                                'stepNumber': 'Step3',
                                'pageName': 'Product Sub Category'
                            }, this.selectedByProductObject);

                            this.router.navigate(['product-category-section', id], navigationExtras);
                        }
                    }
                }),
                    (err => this.onErrorResponse(err)))

        });
    };

    /**
     * @method onErrorResponse is used to trigger when http request return error response.
     * @param err is used for http error response
     */
    onErrorResponse(err) {
        this.spinner$ = false;
        this.error$ = true;
        this.parenterrormessage = err;
    }

    clickedFireStop() {
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'Firestop'
        });
    }

    setStepperSteps() {
        this.stepperObject = this.dataService.changeStepperStepInfo(this.stepperObject, [{ stepValue: 1 }])
        /* Stepper object is changed globally */
        this.dataService.changeStepperObjectGlobal(this.stepperObject);
    }

    // 'clickedStepObject' event is triggred when the user clicks on the stepper
    clickedStepObject(e) {
        /* if the user clicks on any passed step we are setting 'stepperCurrentCount' to keep track of the selected step and show it in red */
        if (e.stepValue == 1) {
            this.stepperWithoutSections()
        }
        this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperCurrentCount: e.stepValue }));
        this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperProgressCount: e.stepValue }));
        this.dataService.changeStepperObjectGlobal(this.stepperObject);
        this.router.navigateByUrl(e.routeUrl);
    }

    ngOnDestroy(): void {
        this.observeStepperObj.unsubscribe();
    }
}
